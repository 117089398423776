import React, { Component } from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const SectionWrapper = styled("div")`
    width: 50%;
    padding-right: 8%;
    color: ${colors.grey600};
    font-weight: 300;
    margin-bottom: 20px;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        width: 100%;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .section-title {
        color: ${colors.green500};
        font-weight: 300;
        text-transform: uppercase;
    }

    .skill-percentage {
        margin-bottom: 10px;
        position: relative;

        &:before {
            content: "";
            height: 6px;
            background: ${colors.green500};
            position: absolute;
            margin-top: 3px;
            bottom: 0;
        }

        &:after {
            content: "";
            width: 100%;
            height: 6px;
            background: ${colors.grey800};
            display: block;
            margin-top: 3px;
        }

        &.html::before {
            width: 90%;
        }

        &.css::before {
            width: 80%;
        }

        &.js::before {
            width: 70%;
        }

        &.react::before {
            width: 65%;
        }

        &.ts::before {
            width: 60%;
        }

        &.jquery::before {
            width: 80%;
        }

        &.git::before {
            width: 50%;
        }

        &.a11y::before {
            width: 85%;
        }

        &.aem::before {
            width: 50%;
        }

        &.mongo::before {
            width: 45%;
        }

        &.express::before {
            width: 80%;
        }

        &.node::before {
            width: 60%;
        }

        &.ps::before {
            width: 50%;
        }
    }
`

class SkillsSection extends Component {
    render() {
        return (
            <SectionWrapper>
                <h3 className="section-title">Skills</h3>
                <ul>
                    <li className="skill-percentage html">HTML</li>
                    <li className="skill-percentage css">CSS / SASS</li>
                    <li className="skill-percentage js">Javascript</li>
                    <li className="skill-percentage react">React</li>
                    <li className="skill-percentage ts">Typescript</li>
                    <li className="skill-percentage jquery">Jquery</li>
                    <li className="skill-percentage git">GIT</li>
                    <li className="skill-percentage a11y">Web Accessibility</li>
                    <li className="skill-percentage aem">AEM</li>
                    <li className="skill-percentage mongo">MongoDB</li>
                    <li className="skill-percentage express">Express</li>
                    <li className="skill-percentage node">Node</li>
                    <li className="skill-percentage ps">Photoshop</li>

                </ul>
            </SectionWrapper>
        );
    }
}

export default SkillsSection;