import React from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";

const CompanyWrapper = styled("div")`
    padding-right: 5%;
    margin-bottom: 60px;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        width: 100%;
        margin-bottom: 20px;
    }

    .company-title {
        color: ${colors.white100};
    }

    .time {
        margin: 1em 0;
        line-height: 1.5;
    }
`
const CompanySection = ({ company, timeframe }) => (
    <CompanyWrapper>
        <div className="company-title">{company}</div>
        <div className="time">{timeframe}</div>
    </CompanyWrapper>
)

export default CompanySection;

CompanySection.propTypes = {
    company: PropTypes.string.isRequired,
    timeframe: PropTypes.string.isRequired,
}