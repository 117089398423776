import React, { Component } from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const ContactInfoWrapper = styled("div")`
    .contact-info {
        display: flex;
        margin-top: 50px;
        font-weight: 300;

        @media(min-width: ${dimensions.maxwidthDesktop}px) {
            margin-top: 100px;
        }
    }
`

const ListTitles = styled("ul")`
    font-weight: 600;
    width: 40%;
    color: ${colors.grey800};
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
    }
`

const ListContent = styled("ul")`
    width: 60%;
    font-weight: 300;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
    }

    a {
        color: ${colors.green500};
        text-decoration: none;
    }
`

class ContactInfo extends Component {
    render() {
        return (
            <ContactInfoWrapper>
                <div className="contact-info">
                    <ListTitles>
                        {/* <li>Call</li> */}
                        <li>Mail</li>
                        <li>Web</li>
                        <li>Home</li>
                    </ListTitles>
                    <ListContent>
                        {/* <li>919-280-0296</li> */}
                        <li><a href="mailto:michael@houseofcrane.com">michael@houseofcrane.com</a></li>
                        <li><a href="http://houseofcrane.com/">houseofcrane.com</a></li>
                        <li>Raleigh, NC</li>
                    </ListContent>
                </div>
            </ContactInfoWrapper>
        );
    }
}

export default ContactInfo;