import React, { Component } from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const SectionWrapper = styled("div")`
    width: 50%;
    color: ${colors.grey600};
    font-weight: 300;
    margin-bottom: 20px;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        width: 100%;
    }

    .section-title {
        color: ${colors.green500};
        font-weight: 300;
        text-transform: uppercase;
    }
`

class HobbiesSection extends Component {
    render() {
        return (
            <SectionWrapper>
                <h3 className="section-title">Hobbies</h3>
                <p>I love exploring the outdoors alongside my wife, daughter, son and our dog, Georgia.</p>
                <p>I also love diving into projects whether it be home improvement, cooking or baking.</p>
            </SectionWrapper>
        );
    }
}

export default HobbiesSection;