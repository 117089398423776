import React from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";

const JobWrapper = styled("div")`
    width: 65%;
    padding-right: 5%;
    margin-bottom: 60px;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        width: 100%;
        margin-bottom: 40px;
    }

    .job-title {
        color: ${colors.white100};
    }
`

const JobSection = ({ title, descriptions }) => (
    <JobWrapper>
        <div className="job-title">{title}</div>
        <div className="company-description">{descriptions.map((description, i) => (
            <p key={i}>{description}</p>
        ))}</div>
    </JobWrapper>
)

export default JobSection;

JobSection.propTypes = {
    title: PropTypes.string.isRequired,
    descriptions: PropTypes.array.isRequired,
}