import React, { Component } from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import profileImage from "../../images/profile.jpg";
import colors from "styles/colors";

const ContactTitleWrapper = styled("div")`
    display: flex;
    flex-wrap: wrap;

    @media(min-width: ${dimensions.maxwidthDesktop}px) {
        flex-wrap: nowrap;
    }

    .picture-resume-wrapper {
        width: 100%;
    }

    .picture-resume {
        width: 200px;
        height: 200px;
        background-size: cover;
        border-radius: 50%;
        margin-right: 0px;
        display: table;
        position: relative;
        vertical-align: middle;

        @media(min-width: ${dimensions.maxwidthDesktop}px) {
            width: 150px;
            height: 150px;
        }

        span {
            display: table-cell;
            vertical-align: middle;
            position: relative;
            margin: 0 auto;
            z-index: 10;
            text-align: center;
        }

        img {
            border-radius: 50%;
            width: 120px;

            @media(min-width: ${dimensions.maxwidthDesktop}px) {
                width: 80px;
            }
        }
    }

    svg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .name-wrapper h1 {
        font-size: 2.5em;
        font-weight: 400;
        text-align: left;
        color: ${colors.grey800};
        text-transform: uppercase;
        line-height: 1em;
        padding-top: 20px;
        margin: 0;

        @media(min-width: ${dimensions.maxwidthDesktop}px) {
            padding-top: 40px;
        }
    }
`

class ContactTitle extends Component {
    render() {
        return (
            <ContactTitleWrapper>
                <div className="picture-resume-wrapper">
                    <div className="picture-resume">
                        <span><img src={profileImage} alt="" /></span>
                        <svg version="1.1" viewBox="0 0 350 350">
                            <defs>
                                <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -9" result="cm" />
                                </filter>
                            </defs>
                            <g filter="url(#goo)">
                                <circle id="main_circle" className="st0" cx="171.5" cy="175.6" r="130" />
                                <circle id="circle" className="bubble0 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble1 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble2 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble3 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble4 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble5 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble6 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble7 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble8 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble9 st1" cx="171.5" cy="175.6" r="122.7" />
                                <circle id="circle" className="bubble10 st1" cx="171.5" cy="175.6" r="122.7" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="name-wrapper">
                    <h1>Michael <br />Crane</h1>
                </div>
            </ContactTitleWrapper>
        );
    }
}

export default ContactTitle;