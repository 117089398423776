import React from "react";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Global } from "@emotion/core";
import { Link } from "gatsby";
import globalStyles from 'styles/global';
import typeStyles from 'styles/typography';
import ContactTitle from "components/_resume/ContactTitle";
import ContactInfo from "../components/_resume/ContactInfo";
import ContactSocial from "../components/_resume/ContactSocial";
import CompanySection from "../components/_resume/CompanySection";
import JobSection from "../components/_resume/JobSection";
import SkillsSection from "../components/_resume/SkillsSection";
import HobbiesSection from "../components/_resume/HobbiesSection";
import ContactCTA from "components/_ui/ContactCTA";

const BackButton = styled("div")`
    margin-top: -1.5em;
`

const BackButtonAction = styled("div")`
    font-weight: 600;
    transition: all 150ms ease-in-out;

    span {
        margin-right: 1em;
        transform: translateX(8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    a {
        text-decoration: none;
        color: ${colors.orange600};
    }

    &:hover {
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`

const ResumeWrapper = styled("div")`
    display: flex;
    font-family: 'Inter var', sans-serif;

    @media(min-width: ${dimensions.maxwidthDesktop}px) {
        max-width: 1300px;
        margin: 0 auto;
    }

    @media(max-width: ${dimensions.maxwidthWeb}px) {
        flex-direction: column;
    }

    p {
        line-height: 1.5;
    }
`

const ProfileSection = styled("section")`
    width: 100%;
    color: ${colors.grey700};
    padding: 40px 10% 20px 5%;

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        padding: 80px 15% 40px 10%;
    }

    @media(min-width: ${dimensions.maxwidthWeb}px) {
        width: 40%;
        padding: 60px 60px 40px 40px;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .st0, 
    .st1 {
        fill: ${colors.green500};
      }

    .contact-presentation {
        margin: 3em 0;

        @media(min-width: ${dimensions.maxwidthDesktop}px) {
            margin: 6em 0;
        }
    }
`

const ExperienceSection = styled("section")`
    background: ${colors.grey800};
    width: 100%;
    color: ${colors.grey600};
    font-weight: 300;
    padding: 40px 10% 20px 5%;

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        padding: 80px 15% 40px 10%;
    }

    @media(min-width: ${dimensions.maxwidthWeb}px) {
        width: 60%;
        padding: 60px 60px 40px 40px;
    }
    
    .container {
        display: flex;
        flex-direction: column;
    } 

    .experience-title {
        color: ${colors.green500};
        font-weight: 300;
        text-transform: uppercase;
    }
`

const JobInfo = styled("div")`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media(min-width: ${dimensions.maxwidthMobile}px) {
        flex-wrap: nowrap;
    }
`

const AdditionalInfo = styled("div")`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media(min-width: ${dimensions.maxwidthMobile}px) {
        flex-wrap: nowrap;
    }
`

const Resume = () => (
    <>
        <Global styles={[globalStyles, typeStyles]} />
        <Helmet
            title={`Resume | House of Crane`}
            titleTemplate={`%s | Resume | House of Crane`}
        />
        <ContactCTA />
        <ResumeWrapper>
            <ProfileSection>
            <BackButton>
                <BackButtonAction>
                    <Link to="/">
                        <span>&#8592;</span> Back
                    </Link>
                </BackButtonAction>
            </BackButton>
                <div className="container">
                    <ContactTitle />
                    <ContactInfo />
                    <div className="contact-presentation">
                        <p><strong>Highly</strong> motivated frontend developer with technical skills in web applications design and development, possessing knowledge of various languages and tools with a fierce passion around the user experience.</p>
                    </div>
                    <ContactSocial />
                </div>
            </ProfileSection>
            <ExperienceSection>
                <div className="container">
                    <h3 className="experience-title">Experience</h3>
                    <div className="experience-wrapper">
                        <JobInfo>
                            <CompanySection
                                company="Blue Acorn ICI"
                                timeframe="Oct 2018 - Present"
                            />
                            <JobSection
                                title="Frontend Developer"
                                descriptions={[
                                    'Develop custom components, dialogs and templates using XML, Sass, Typescript and Sightly / HTL templating language',
                                    'Develop frontend components to showcase within a component library built on the fractal framework, in addition to prototypes for testing FED designs with frontend components',
                                    'Develop frontend unit tests using Mocha and SinonJS',
                                    'Collaborate with other team members and stakeholders',
                                    'Involvement in all sprint planning meetings and coordination with other frontend development resources to ensure work is completed on time'
                                ]}
                            />
                        </JobInfo>
                        <JobInfo>
                            <CompanySection
                                company="3S Ventures"
                                timeframe="Nov 2016 - Sep 2017"
                            />
                            <JobSection
                                title="Frontend Developer"
                                descriptions={[
                                    'Translated designs to front-end code and determined developer requirements and specifications',
                                    'Developed responsive web pages and UI components using HTML, CSS, JavaScript, jQuery and Bootstrap',
                                    'Collaborated with content and analytics teams on the execution of ideas and projects',
                                    'Wrote maintainable, scalable, responsive, and cross-browser code',
                                    'Updated and streamlined existing code to improve site performance',
                                    'Designed and developed views/templates using the Swig templating engine to integrate into CMS',
                                    'Performed A/B testing and coordinated with clients in page optimizations'
                                ]}
                            />
                        </JobInfo>
                        <JobInfo>
                            <CompanySection
                                company="Merrill Lynch"
                                timeframe="May 2011 - Jan 2016"
                            />
                            <JobSection
                                title="Financial Advisor"
                                descriptions={[
                                    'Licensed in Life, Accident & Health Insurance, Series 66 & 7',
                                    'Graduate of the Practice Management Development (PMD) program',
                                    'Provided comprehensive financial planning for client base of high net-worth individuals',
                                    'Oversaw investments and proactively managed client funds',
                                    'Engaged in business-continuance and estate planning, implemented and administered 40lk and employee benefits, and orchestrated and funded deferred compensation'
                                ]}
                            />
                        </JobInfo>
                        <JobInfo>
                            <CompanySection
                                company="DermatechMD"
                                timeframe="Oct 2010 - May 2011"
                            />
                            <JobSection
                                title="SEO Specialist"
                                descriptions={[
                                    'Developed link building strategies to improve SEO positions for targeted keywords',
                                    'Improved organic search results and pay per click (PPC) campaigns through identification of profitable keywords'
                                ]}
                            />
                        </JobInfo>
                    </div>
                    <AdditionalInfo>
                        <SkillsSection />
                        <HobbiesSection />
                    </AdditionalInfo>
                </div>
            </ExperienceSection>
        </ResumeWrapper>
    </>
);

export default () => {
    return (
        <Resume />
    )
}
