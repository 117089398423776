import React, { Component } from 'react';
import styled from "@emotion/styled";
import colors from "styles/colors";

const ContactSocialWrapper = styled("div")`
    .contact-social {
        display: flex;
        font-weight: 300;
    }
`

const ListTitles = styled("ul")`
    font-weight: 600;
    width: 40%;
    color: ${colors.grey800};
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
    }
`

const ListContent = styled("ul")`
    width: 60%;
    font-weight: 300;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
    }

    a {
        color: ${colors.green500};
        text-decoration: none;
    }
`

class ContactSocial extends Component {
    render() {
        return (
            <ContactSocialWrapper>
                <div className="contact-social">
                    <ListTitles>
                        <li>Twitter</li>
                        <li>Linkedin</li>
                        <li>GitHub</li>
                    </ListTitles>
                    <ListContent>
                        <li><a href="https://twitter.com/houseofcrane">@houseofcrane</a></li>
                        <li><a href="https://www.linkedin.com/in/mike-r-crane/">Michael Crane</a></li>
                        <li><a href="https://github.com/mike-crane">mike-crane</a></li>
                    </ListContent>
                </div>
            </ContactSocialWrapper>
        );
    }
}

export default ContactSocial;